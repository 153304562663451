"use client";

import { useState, useContext } from "react";
import { flashContext } from "@/context/flash-context";
import { sidebarContext } from "@/context/sidebar-context";
import { handleResponse } from "@/hooks/utils";
import { ApiWriteResponse, UseSubmitOptions, UseSubmitReturn, ViolationApiResponse } from "@/TS/Interfaces/global_interfaces";
import { removeEmptyFields } from "@/utils/global-utils";
function UseSubmit<TReturnedObject, TData, TResponse extends ApiWriteResponse<TReturnedObject>>(fetchApi: (data: TData, ...args: Array<any>) => Promise<TResponse>, options?: UseSubmitOptions): UseSubmitReturn<TData> {
  const [errors, setErrors] = useState<undefined | any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    applyFlashContext
  } = useContext(flashContext);
  const {
    setVal: setSidebarValue
  } = useContext(sidebarContext);
  async function onSubmit(data: TData, ...args: any[]): Promise<void> {
    removeEmptyFields(data);
    setErrors(undefined);
    setLoading(true);
    try {
      const res: TResponse = await fetchApi(data, ...args);
      setLoading(false);
      handleResponse<TResponse>(res, {
        ...options,
        applyFlashContext,
        setErrors,
        setLoading,
        setSidebarValue
      });
    } catch (error) {
      // Generic Errors
      setLoading(false);
      setErrors([{
        message: (error as Error).message
      }]);
    }
  }
  return {
    errors,
    setErrors,
    loading,
    setLoading,
    onSubmit,
    setVal: setSidebarValue
  };
}
export default UseSubmit;