import { twMerge } from "tailwind-merge";
import Icon from "@/components/icon";
export default function Flash(props) {
  const type = props.type || "primary";
  return <div className={twMerge(`w-full rounded flex items-center gap-3.5 text-white p-4 mb-6 h-fit ${props.className}`, `bg-${type}`, type === "primary" && "bg-opacity-25 text-primary ring-primary ring-1 ring-opacity-70")} data-sentry-component="Flash" data-sentry-source-file="flash.tsx">
      {(type === "success2" || type === "success") && <div className="flex  rounded-full bg-white w-fit aspect-square">
          <Icon icon={"check-simple"} className={"!fill-success2"}></Icon>
        </div>}

      {props.children}
      {/*<Icon icon={"cross"}></Icon>*/}
    </div>;
}