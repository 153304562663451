import {
  ApiWriteResponse,
  UseSubmitOptions,
  ViolationApiResponse,
} from "@/TS/Interfaces/global_interfaces";

export function handleResponse<TResponse>(
  res: ApiWriteResponse<TResponse>,
  options: UseSubmitOptions,
) {
  if (!res.error) {
    if (typeof options.callbackMessage === "object" && "message" in res) {
      options.callbackMessage.message = (res as any).message;
    }

    if (options.sidebarJsx !== undefined) {
      options.setSidebarValue(options.sidebarJsx);
    }

    if (
      typeof options.callbackMessage === "object" &&
      (options.callbackMessage.type === "success" ||
        options.callbackMessage.type === "success2")
    ) {
      options.applyFlashContext(
        options.callbackMessage,
        options.callbackMessageTimeoutMs,
      );
    }

    if (options.callback) {
      options.callback();
    }
  } else {
    handleErrors<TResponse>(res, options);
  }
}

const handleErrors = <TResponse>(
  res: ApiWriteResponse<TResponse>,
  options: UseSubmitOptions,
): void => {
  if (
    typeof options.callbackMessage === "object" &&
    options.callbackMessage.type === "primary"
  ) {
    options.applyFlashContext(
      options.callbackMessage,
      options.callbackMessageTimeoutMs,
    );
    // for inputs error message
  } else if ("violations" in res && res.violations) {
    options.setErrors(res.violations);
  }
  if (res.error && res.message) {
    options.applyFlashContext({
      message: res.message || "An error occurred",
      type: "primary",
      position: options.errorFlashPath,
    });
  }

  //look for violation with empty propertyPath (to display error)
  const violationWithoutPath: ViolationApiResponse = res?.violations.find(
    (element: ViolationApiResponse) =>
      "" === element.propertyPath || !element.propertyPath,
  );
  if (violationWithoutPath) {
    options.applyFlashContext({
      message: violationWithoutPath.message || "An error occurred",
      type: "primary",
      position: options.errorFlashPath,
    });
  } else {
    options.applyFlashContext({
      message:
        res.message ||
        "Une erreur s'est produite lors de l'envoi du formulaire.",
      type: "primary",
      position: options.errorFlashPath,
    });
  }
};
